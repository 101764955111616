<template>
    <div class="container1150">
        <header>
            <strong>
                深圳市创环环保科技有限公司关于参与申报2022年度广东省科学技术奖提名工作的公示
            </strong>
        </header>
        <main>
            <div class="img1">
                <img src="/img/news/news5-1.jpg" alt="" />
            </div>
            <div class="img1">
                <img src="/img/news/news5-2.jpg" alt="" />
            </div>
            <div class="img1">
                <img src="/img/news/news5-3.jpg" alt="" />
            </div>
        </main>
        <!-- <a
            href="http://120.79.130.126:6082/%E5%85%AC%E7%A4%BA%E6%9D%90%E6%96%99.pdf"
            >附件下载</a
        > -->
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            // width: 100%;
            width: 700px;
            height: 900px;
            margin: 10px auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>